import { Component, OnInit, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import Swal from "sweetalert2";

import { MustMatch } from "../../../shared/validators/must-match.validator";
import { AppValidators } from "../../../configs/app-validator.config";

import { UserService } from "../../../core/services/user.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-user-change-pwd",
  templateUrl: "./user-change-pwd.component.html",
  styleUrls: ["./user-change-pwd.component.css"],
})
export class UserChangePwdComponent implements OnInit {
  
  public event: EventEmitter<string> = new EventEmitter();

  public title: string;
  public isSubmit: boolean;
  public isLoading: boolean = false;

  private cpid: string;
  private uid: string;
  private result: string;

  public changePasswordForm: FormGroup;

  constructor(
    private form: FormBuilder,
    private modalRef: BsModalRef,
    private userService: UserService
  ) {}

  ngOnInit() {
    const currentUser = JSON.parse(
      sessionStorage.getItem("currentUser") || "{}"
    );
    this.cpid = currentUser.cpid;
    this.uid = currentUser.uid;
    this.createFormGroup();
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  createFormGroup() {
    this.changePasswordForm = this.form.group(
      {
        old: new FormControl("", [Validators.required]),
        new: new FormControl("", [
          Validators.required,
          Validators.pattern(AppValidators.PASSWORD),
        ]),
        confirm: new FormControl("", [Validators.required]),
      },
      {
        validators: MustMatch("new", "confirm"),
      } 
    );
  }

  onSubmit() {
    this.isSubmit = true;

    if (this.changePasswordForm.invalid) {
      return;
    }

    this.userService
      .changeUserPassword(
        this.cpid,
        this.uid,
        this.changePasswordForm.get("old").value,
        this.changePasswordForm.get("new").value
      )
      .subscribe({
        next: (res) => {
          if (res.status.toLowerCase() === "success") {
            this.event.emit(this.changePasswordForm.get("new").value);
            this.closed();
            this.result = this.changePasswordForm.get("new").value;
            Swal.fire({
              title: "Success",
              text: "Password has been successfully changed.",
              type: "success",
              // onOpen: () => {
              //   Swal.showLoading();
              // },
            });
          } else {
            Swal.fire("Error", res.message, "error");
          }
        },
        error: (err) => {
          console.log(err);
          // this.event.emit(null);
          // this.closed();
          Swal.fire("Error", err.message, "error");
        }
      });
  }

  closed() {
    this.modalRef.hide();
  }
}
