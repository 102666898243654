export const environment = {
  production: true,
  runmode: "export",
  apiEndpoint: "/api",
  apiTimeout: 5000,
  apiRetry: 2,
  timezone: "Asia/Bangkok",
  baseHref: "/guarantee/",
  reportDateRange: 90,
  permissionCode: {
    CREATE_USER_ADMIN: "CREATE_USER_ADMIN",
    CREATE_ADMIN: "CREATE_ADMIN",
    CREATE_SYSTEM_ADMIN: "CREATE_SYSTEM_ADMIN",
    CREATE_USER: "CREATE_USER",
  },
  group: {
    ADMIN: "OFFICER ADMIN",
    USER_ADMIN: "USER ADMIN",
    SYSTEM_ADMIN: "SYSTEM ADMIN",
    USER: "USER",
  },
  application: {
    name: "e-Guarantee Interface Service",
    headerMainName: "User",
    headerSubName: "Management",
    licenceName: "e-Guarantee Interface Service",
    icon: "fa-users",
    version: "1.0.0",
    by: "Netbay Public Co., Ltd.",
    homePage: "/mainmenu",
    autoCloseSidebar: "false"
  }
};
