import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { EnsureModuleLoadedOnceGuard } from "../core/guards/ensure-module-loaded-once.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientInterceptor,
  ErrorHandlerInterceptor,
  ResponseInterceptor,
} from "../core/interceptors";
import { fakeBackendProvider } from "../core/interceptors";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [RouterModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    // provider used to create fake backend
    // fakeBackendProvider
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
