import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import Swal from "sweetalert2";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: any) => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.status === "fail") {
            if (event.body.code === "AUTHENTICATION_FAIL") {
              Swal.fire({
                title: "Authentication",
                text: event.body.message,
                type: "warning",
                buttonsStyling: false,
                showCancelButton: false,
                confirmButtonText: "Close",
                confirmButtonClass: "btn btn-secondary btn-fill swal2-close-btn",
              }).then((result) => {
                sessionStorage.removeItem("currentUser");
                sessionStorage.removeItem("permissionList");
                this.router.navigate(["/auth/login"]);
              });
            } else if (event.body.code === "SESSION_EXPIRE") {
              Swal.fire({
                title: "Authentication",
                text: "Session Expire",
                type: "warning",
                buttonsStyling: false,
                showCancelButton: false,
                confirmButtonText: "Close",
                confirmButtonClass: "btn btn-secondary btn-fill swal2-close-btn",
              }).then((result) => {
                sessionStorage.removeItem("currentUser");
                sessionStorage.removeItem("permissionList");
                this.router.navigate(["/auth/login"]);
              });
            }
          }
        }
      })
    );
  }
}
