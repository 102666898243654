import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    const httpErrorCode = response["status"];

    switch (httpErrorCode) {
      case 401:
        this.router.navigateByUrl("/login");
        break;
      case 403:
      case 404:
        this.router.navigateByUrl("/not-found");
        break;
      default:
        console.log("Error " + response["message"]);
    }

    throw response;
  }
}
