import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { timeout, retry } from "rxjs/operators";
import { Observable } from "rxjs";
import { AppConfig } from "../../../app/app-config.module";

import { User, SearchUser, ICheckDuplicateUser, IUser, ExportExcelUser, IUserNew } from "../../shared/models/user.model";
import { IPagination, IResponse } from "../../shared/models/response.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) {}

  getUserList(
    pageNum: number,
    pageSize: number,
    search: SearchUser
  ): Observable<IResponse<IPagination<IUser>>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/getlistuser`, {
        pageSize: pageSize,
        pageNum: pageNum,
        ...search,
      })
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }
  getUserListByUserVerify(
    pageNum: number,
    pageSize: number,
    search: SearchUser
  ): Observable<IResponse<IPagination<IUser>>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/api/user/get-list-user`, {
        pageSize: pageSize,
        pageNum: pageNum,
        ...search,
      })
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }
  
  getUserById(uid: string): Observable<IResponse<IUser>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/getuser`, {
        id: uid,
      })
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  getUserByIdInformation(uid: string): Observable<IResponse<IUser>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/getuserinformation`, {
        id: uid,
      })
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  createUser(user: User): Observable<IResponse<IUser>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/createuser`, user);
  }

  updateUser(user: User): Observable<IResponse<void>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/updateuser`, user)
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  updateUserInformation(user: User): Observable<IResponse<void>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/updateuserinformation`, user)
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  deleteUser(uid: string): Observable<any> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/deleteuser`, {
        id: uid,
      })
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  changeGroupUser(user: User): Observable<IResponse<void>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/userchangegroup`, user)
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  updateStatusUser(user: User): Observable<IResponse<void>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/users/updateStatus`, user)
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  checkDuplicateUser(username: string): Observable<IResponse<ICheckDuplicateUser>> {
    return this.http
      .post<IResponse<ICheckDuplicateUser>>(`${this.config.apiEndpoint}/users/checkduplicateusername`, {
        username: username,
      })
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  changeUserPassword(
    cpid: string,
    uid: string,
    oldpassword: string,
    newpassword: string
  ): Observable<IResponse<void>> {
    return this.http
      .post<IResponse<void>>(`${this.config.apiEndpoint}/users/changepassword`, {
        cpid: cpid,
        oldPassword: oldpassword,
        password: newpassword,
        uid: uid,
      })
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }

  resetPassword(user: User): Observable<IResponse<void>> {
    return this.http
      .post<IResponse<void>>(`${this.config.apiEndpoint}/users/resetpassword`, user);
  }

  unlockUser(user: User): Observable<IResponse<void>> {
    return this.http
      .post<IResponse<void>>(`${this.config.apiEndpoint}/users/unlock-user`, user);
  }
  
  exportExcelUser(request: ExportExcelUser){
    return this.http.post(
      `${this.config.apiEndpoint}/user/export-excel`,
      request,
      { responseType: "blob" }
    );
  }

  getUserListNew(
    pageNum: number,
    pageSize: number,
    search: SearchUser
  ): Observable<IResponse<IPagination<IUserNew>>> {
    return this.http
      .post<any>(`${this.config.apiEndpoint}/api/user/get-list-user`, {
        pageSize: pageSize,
        pageNum: pageNum,
        ...search,
      })
      .pipe(timeout(this.config.apiTimeout), retry(this.config.apiRetry));
  }
}
