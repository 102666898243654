import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(
      sessionStorage.getItem("currentUser") || null
    );

    if (currentUser && Object.entries(currentUser).length !== 0) {
      request = request.clone({
        setHeaders: {
          uid: currentUser.uid,
          ucode: currentUser.ucode,
        },
      });
    }

    return next.handle(request);
  }
}
