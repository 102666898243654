<div class="modal-header gradient-modal p-2">
  <h5 class="modal-title pull-left">{{ title }}</h5>
  <button title="close" type="button" id="change-pwd-x-btn" class="close pull-right text-light" aria-label="Close" (click)="closed()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body gradient-modal p-2">
  <form class="modal-body-content" autocomplete="off" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="row">
      <div class="col-12 col-lg-12 form-row form-group align-items-start mb-2">
        <label for="change-pwd-old" class="col-form-label col-form-label-sm col-sm-3 text-sm-right card-control-column">Old Password&nbsp;<strong class="text-danger">*</strong></label>
        <div class="col-sm-7">
          <input class="form-control form-control-sm bg-transparent p-1 card-control-value" type="password"
            placeholder="Old Password" id="change-pwd-old"
            [ngClass]="{ 'is-invalid': f.old.errors, 'is-valid': !f.old.errors }" formControlName="old">
          <div class="invalid-tooltip" *ngIf="f.old.errors && (f.old.dirty || f.old.touched || isSubmit)">
            <span *ngIf="f.old.errors.required">
              Old password is required.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-12 form-row form-group align-items-start mb-2">
        <label for="change-pwd-new" class="col-form-label col-form-label-sm col-sm-3 text-sm-right card-control-column">New Password&nbsp;<strong class="text-danger">*</strong></label>
        <div class="col-sm-7">
          <input class="form-control form-control-sm bg-transparent p-1 card-control-value" type="password"
            placeholder="New Password" id="change-pwd-new"
            [ngClass]="{ 'is-invalid': f.new.errors, 'is-valid': !f.new.errors }" formControlName="new">
          <div class="invalid-tooltip" *ngIf="f.new.errors && (f.new.dirty || f.new.touched || isSubmit)">
            <span *ngIf="f.new.errors.required">
              New password is required.
            </span>
            <span *ngIf="f.new.errors.pattern">
              New password should be at least 8-30 characters, Lowercase letters, Uppercase letters, Numbers and Special
              characters.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-12 col-lg-12 form-row form-group align-items-start mb-2">
        <label for="change-pwd-confirm" class="col-form-label col-form-label-sm col-sm-3 text-sm-right card-control-column">Confirm Password&nbsp;<strong class="text-danger">*</strong></label>
        <div class="col-sm-7">
          <input class="form-control form-control-sm bg-transparent p-1 card-control-value" type="password"
            placeholder="Confirm Password" id="change-pwd-confirm"
            [ngClass]="{ 'is-invalid': f.confirm.errors, 'is-valid': !f.confirm.errors }" formControlName="confirm">
          <div class="invalid-tooltip" *ngIf="f.confirm.errors && (f.confirm.dirty || f.confirm.touched || isSubmit)">
            <span *ngIf="f.confirm.errors.required">
              Confirm password is required.
            </span>
            <span *ngIf="f.confirm.errors.mustMatch">
              Confirm password is not match with the new password.
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer gradient-modal p-0">
  <button title="Change Password" type="button" id="change-pwd-change-btn" class="btn btn-sm btn-light" (click)="onSubmit()"><i
      class="fa fa-unlock-alt p-1" aria-hidden="true"></i>Change Password</button>
</div>