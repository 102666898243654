import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import { AppComponent } from "./app.component";
import { AppConfig } from "./app-config.module";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";

import { ToastrModule } from "ngx-toastr";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { ModalModule } from "ngx-bootstrap/modal";

import { UserChangePwdsModule } from "./modules/user-change-pwds/user-change-pwds.module";
import { UserChangePwdComponent } from "./modules/user-change-pwds/user-change-pwd/user-change-pwd.component";

import { UserProfilesModule } from "./modules/user-profiles/user-profiles.module";
import { UserProfileComponent } from "./modules/user-profiles/user-profile/user-profile.component";

import { toAstConfig } from "./configs/app-package.config";
import { Permissions } from './core/_tools/permissions';
import { AppConstant } from './_constant/app.constant';
import { environment } from '../environments/environment';
import { NotificationAppConfig } from "angular-notification/src/app/core/config/notification.config";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { NgxSpinnerModule } from "ngx-spinner";

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export const APP_DI_CONFIG: AppConfig = {
  apiEndpoint: environment.apiEndpoint,
  apiTimeout: environment.apiTimeout,
  apiRetry: environment.apiRetry,
  runmode: environment.runmode,
  permissionCode: environment.permissionCode,
  group: environment.group,
  application: environment.application,
  reportDateRange: environment.reportDateRange
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    UserChangePwdsModule,
    UserProfilesModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    CollapseModule.forRoot(),
    ToastrModule.forRoot(toAstConfig),
    NgxMaskModule.forRoot(),
    NgxSpinnerModule
  ],
  exports: [],
  entryComponents: [UserChangePwdComponent, UserProfileComponent],
  bootstrap: [AppComponent],
  providers : [
    Permissions,
    AppConstant,
    { provide: AppConfig , useValue: APP_DI_CONFIG },
    { provide: NotificationAppConfig, useValue: APP_DI_CONFIG },
    { provide: APP_BASE_HREF, useValue: environment.baseHref }
  ]
})
export class AppModule {}
