import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";

export let routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./modules/exim-auth/exim-auth.module").then(
        (module) => module.EximAuthModule
    ),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./modules/register/register.module").then(
        (module) => module.RegisterModule
    )
  },
  {
    path: "mainmenu",
    loadChildren: () =>
      import("./modules/mainmenu.module").then(
        (module) => module.MainmenuModule
    ),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/auth/login",
  },
  {
    path: "**",
    redirectTo: "/auth/login",
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
