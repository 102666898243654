<div class="modal-header gradient-modal p-2">
  <h5 class="modal-title pull-left">{{ title }}</h5>
  <button title="close" type="button" id="profile-x-btn" class="close pull-right text-light" aria-label="Close" (click)="closed()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body gradient-modal p-2">
  <form class="modal-body-content" autocomplete="off" [formGroup]="profileForm" novalidate>
    <div class="col-12 col-lg-12 form-row form-group align-items-start mb-2">
      <label for="profile-username" class="col-form-label col-form-label-sm col-sm-2 text-sm-right card-control-column">Username</label>
      <div class="col-sm-9">
        <input class="form-control form-control-sm bg-transparent p-1 card-control-value" type="text" placeholder="" id="profile-username"
          formControlName="username">
      </div>
    </div>
    <div class="col-12 col-lg-12 form-row form-group align-items-start mb-2">
      <label for="profile-firstname" class="col-form-label col-form-label-sm col-sm-2 text-sm-right card-control-column">Firstname</label>
      <div class="col-sm-9">
        <input class="form-control form-control-sm bg-transparent p-1 card-control-value" type="text" placeholder="" id="profile-firstname"
          formControlName="firstName">
      </div>
    </div>
    <div class="col-12 col-lg-12 form-row form-group align-items-start mb-2">
      <label for="profile-lastname" class="col-form-label col-form-label-sm col-sm-2 text-sm-right card-control-column">Lastname</label>
      <div class="col-sm-9">
        <input class="form-control form-control-sm bg-transparent p-1 card-control-value" type="text" placeholder="" id="profile-lastname"
          formControlName="lastName">
      </div>
    </div>
    <div class="col-12 col-lg-12 form-row form-group align-items-start mb-2">
      <label for="profile-email" class="col-form-label col-form-label-sm col-sm-2 text-sm-right card-control-column">Email</label>
      <div class="col-sm-9">
        <input class="form-control form-control-sm bg-transparent p-1 card-control-value" type="text" placeholder="" id="profile-email"
          formControlName="email">
      </div>
    </div>
  </form>
</div>
<div class="modal-footer gradient-modal p-0">
  <button title="Close" type="button" id="profile-close-btn" class="btn btn-sm btn-light" (click)="closed()">
    <i class="fa fa-times p-1" aria-hidden="true"></i>Close</button>
</div>