import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { IConfig } from "ngx-mask";
import { ModalOptions } from "ngx-bootstrap/modal";
import { SweetAlertOptions } from "sweetalert2";
import { ToastrModule } from "ngx-toastr";

export let bsConfig: Partial<BsDatepickerConfig> = Object.assign(
  {},
  {
    containerClass: "theme-default",
    isAnimated: true,
    dateInputFormat: "YYYY-MM-DD",
    customTodayClass: "custom-today-class",
  }
);

export let sw2Config: SweetAlertOptions = {};

export let mdConfig: ModalOptions = {
  animated: false,
  class: "modal-xl",
  ignoreBackdropClick: false,
  keyboard: true,
};

export let maskConfig: Partial<IConfig> | (() => Partial<IConfig>) = {};

export let toAstConfig: ToastrModule = {
  timeOut: 10000,
  positionClass: "toast-bottom-right",
  closeButton: true,
  progressBar: true,
};
