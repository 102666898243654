import { Injectable } from '@angular/core';

@Injectable()
export class AppConstant {

     constructor() {}

     public static get Permissions() {
          //return new Permissions();
          return JSON.parse(sessionStorage.getItem("permissionList") ? sessionStorage.getItem("permissionList") : "{}");
     }

     public static HTTP_STATUS_CODE_SUCCESS: string = '200';
     public static HTTP_STATUS_CODE_BAD_REQUEST: string = '400';
     public static HTTP_STATUS_CODE_UNAUTHORIZED: string = '401';
     public static HTTP_STATUS_CODE_NOT_FOUND: string = '404';
     public static HTTP_STATUS_CODE_INTERNAL_ERROR: string = '500';
     public static HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY: string = '422';
   
     // HTTP method
     public static HTTP_METHOD_GET: string = "GET";
     public static HTTP_METHOD_POST: string = "POST";
     public static HTTP_METHOD_PUT: string = "PUT";
     public static HTTP_METHOD_DELETE: string = "DELETE";

}

export enum ConponentType {
     ngModel,
     FormControl
}

export enum YesNo {
     NO, YES
}

export enum ACTIVE {
     INACTIVE = 0, ACTIVE = 1
}

export enum BULevel {
     SB = "SB", 
     B = "B"
}

export class Permissions {
     public SYS_ADMIN="SYS_ADMIN";

     public COMPANY_CREATE="COMPANY_CREATE";
     public COMPANY_EDIT="COMPANY_EDIT";
     public COMPANY_VIEW="COMPANY_VIEW";

     public GROUP_CREATE="GROUP_CREATE";
     public GROUP_EDIT="GROUP_EDIT";
     public GROUP_VIEW="GROUP_VIEW";
     public  GROUP_DELETE="GROUP_DELETE";

     public USER_CREATE="USER_CREATE";
     public USER_EDIT="USER_EDIT";
     public USER_VIEW="USER_VIEW";

     public ROLE_CREATE="ROLE_CREATE";
     public ROLE_EDIT="ROLE_EDIT";
     public ROLE_VIEW="ROLE_VIEW";

     public PERMISSION_CREATE="PERMISSION_CREATE";
     public PERMISSION_EDIT="PERMISSION_EDIT";
     public PERMISSION_VIEW="PERMISSION_VIEW";
     public PERMISSION_DELETE="PERMISSION_DELETE";

     public MENU_CREATE="MENU_CREATE";
     public MENU_EDIT="MENU_EDIT";
     public MENU_VIEW="MENU_VIEW";
     public MENU_DELETE="MENU_DELETE";

     public REGISTER_VIEW="REGISTER_VIEW";
     public REGISTER_APPROVE="REGISTER_APPROVE";
     public REGISTER_REJECT="REGISTER_REJECT";
     public REGISTER_RESEND_EMAIL="REGISTER_RESEND_EMAIL";
}

export enum ResponseStatus {

     success = "success",
     fail = "fail",
     error = "error"
}

export class MessageValidate {

     public static createSuccess = (msg) => `${msg} has been created.`;
     public static createFail = (msg) => `${msg} create failure.`;
     public static updateSuccess = (msg) => `${msg} has been updated.`;
     public static updateFail = (msg) => `${msg} update failure.`;
     public static duplicated = (msg) => `${msg} is duplicated.`;

}
