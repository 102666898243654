import { NgModule, InjectionToken } from "@angular/core";
import { environment } from "../environments/environment";
//export let APP_CONFIG = new InjectionToken<AppConfig>("app.config");

export class AppConfig {
  apiEndpoint: string;
  apiTimeout: number;
  apiRetry: number;
  runmode: string;
  permissionCode: { [key: string]: string };
  group: { [key: string]: string };
  application: { [key: string]: string };
  reportDateRange: number;
}

