import { Injectable } from '@angular/core';
import { AppConstant } from '../../_constant/app.constant';

@Injectable()
export class Permissions {

     constructor() {

     }

     checkPermission(permissionName:string){
          let permission: any = AppConstant.Permissions;
          let user = JSON.parse(sessionStorage.getItem('currentUser'));
          const userPermission = user.permissions;
          let data = userPermission.find(p => p == permission.SYS_ADMIN);
          if (data != undefined && data != null) {
               return true;
          }
          data = userPermission.find( p => p == permissionName);
          if (data != undefined && data != null) {
              return true;
          } else{
              return false;
          }
    }

    checkPermissionList(permissionName: string[]) {
        let permission: any = AppConstant.Permissions;
        let user = JSON.parse(sessionStorage.getItem('currentUser'));
        const userPermission: string[] = user.permissions;
        let data = userPermission.find(p => p == permission.SYS_ADMIN);
        if (data != undefined && data != null) {
            return true;
        }
        return permissionName.some(per => userPermission.includes(per));
    }

    checkIsSystemAdmin() {
        const permission: any = AppConstant.Permissions;
        const user = JSON.parse(sessionStorage.getItem('currentUser'));
        const userPermission = user.permissions;
        const data = userPermission.find(p => p === permission.SYS_ADMIN);
        return data !== undefined && data != null;
    }

}
