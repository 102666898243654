import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent implements OnInit {
  public title: string;
  public isLoading: boolean = false;

  public profileForm: FormGroup;

  constructor(private modalRef: BsModalRef) {}

  ngOnInit() {
    const currentUser = JSON.parse(
      sessionStorage.getItem("currentUser") || "{}"
    );
    this.createFormGroup(currentUser);
  }

  createFormGroup(user: any) {
    this.profileForm = new FormGroup({
      username: new FormControl(user.username),
      firstName: new FormControl(user.firstname),
      lastName: new FormControl(user.lastname),
      email: new FormControl(user.email),
    });
    this.profileForm.disable();
  }

  closed() {
    this.modalRef.hide();
  }
}
